<template>
  <section id="history" v-show="searches.length > 0 || properties.length > 0" class="hist" :class="{ 'visible': show || propOnly, 'home': isHome, 'hide': !properties.length && !searches.length, 'only-prop': propOnly, 'properties': properties.length }">
    <h2 class="head">History</h2>
    <h3 class="sub">Your previous searches and properties viewed
      <span v-if="isHome" @click="toggleHistory" v-show="show" class='toggle' :class="{'visible': showToggle}">Hide history
        <svg class="ico"><use xlink:href="#icon-arr"></use></svg>
      </span>
      <span v-if="isHome" @click="toggleHistory" v-show="!show" class='toggle' :class="{'visible': showToggle}">Show history
        <svg class="ico"><use xlink:href="#icon-arr"></use></svg>
      </span>
    </h3>
    <div class="sec" v-if="searches.length > 0">
      <ul class="list" :class="{'two': colClass === 'two'}">
        <searchCard
             v-for="search in searches"
             :key="search.id"
             :search="search"
             :clName="colClass"
             @track="trackEv"
             @ppcTrackUserClick="ppcTrackUserClick"
        />
      </ul>
    </div>
    <div class="sec sec_properties" v-if="properties.length > 0">
      <ul class="list" :class="{'two': colClass === 'two'}">
        <propCard
            v-for="property in properties"
            :key="property.id"
            :property="property"
            :clName="colClass"
            :date="''"
            :nights="''"
            :card="cardClass"
            @track="trackEv"
            @ppcTrackUserClick="ppcTrackUserClick"
        />
      </ul>
    </div>
    <a v-if="isHome" href="/history" class="link">Go to history</a>
  </section>
</template>

<script>
import searchCard from 'shared/card_search.vue'
import propCard from 'shared/card_hist.vue'

export default {
  data: function () {
    return {
        properties: [],
        searches: [],
        booking: [],
        isLocal: st.checkLocal(),
        track: window.options.track,
        show: false,
        cardClass: "card trans",
        showToggle: true,
        propOnly: false
    }
  },
  props: {
      colClass: String,
      isHome: Boolean,
      thisWeekend: String
  },
  components: {
      searchCard, propCard
  },
  methods: {
      getHistory (item, limit) {
          let app = this;
          fetch('/history.json?type=' + item, this.getRequest())
          .then(function(resp) {
              if (resp.status === 200) {
                return resp.json();
              } else {
                return;
              }
          })
          .then(data => {
              let history = data,
                  newData = [];
              if (history !== null && history.length > 0) {
                  newData = history.reverse().slice(0, limit);
                  return newData;
              } else {
                  return [];
              }
          })
          .then(data => {
            if(item === "property") {
              app.properties = data;
              app.shouldShowToggle();
            } else if(item === "search") {
              app.searches = data;
              app.cleanSearchHistory();
              app.shouldShowToggle();
            } else if(item === "booking") {
              app.booking = data;
            }
          })
      },
      trackEv: function(event, id) {
          let app = this;
          st.tdTrack("uiactions", {
              category: "history_" + event,
              value: {
                  id: id
              },
              visitor_email_id: app.track.visitor_email_id,
              tracking_profile_id: app.track.tracking_profile_id
          });
      },
      ppcTrackUserClick: function(event, id) {
        if (window.location.pathname.includes('ppclanding')) {
          st.tdTrack("uiactions", {
            category: "ppc_history_" + event,
            value: {
              id: id
            },
            visitor_email_id: this.track.visitor_email_id,
            tracking_profile_id: this.track.tracking_profile_id
          });
        }
      },
      toggleHistory: function () {
          this.show = !this.show;
      },
      cleanSearchHistory() {
          let app = this;
          let today = new Date().getTime(),
              allRecords = app.searches,
              records = [];
          for (let i = 0; i < allRecords.length; i++) {
              let record = allRecords[i];
              if ((record.travel_origin_postcode !== "" || record.region !== "" ) && new Date(record.check_in_on).getTime() > today) {
                  if (records.length < 3) {
                      records.push(record);
                  }
              }
          }
          if (records.length === 1 || records.length === 2) {
              app.pushTopDealsCard(records);
          }
          app.searches = records;
      },
      pushTopDealsCard: function(records) {
          records.push({
              region: "Latest UK deals",
              check_in_on: this.$props.thisWeekend,
              minimum_nights: 3,
              nights: 3,
              isochrone_uuid: ""
          });
      },
      getRequest() {
        return {
            method: 'GET',
            ...this.requestData()
        }
      },
      requestData () {
        return {
          credentials: 'include',
          headers: {
            'Content-Type': "application/x-www-form-urlencoded; charset=utf-8",
            'X-CSRF-TOKEN': st.getCSRFToken(),
          }
        }
      },
      shouldShowToggle: function() {
        this.showToggle = (this.searches.length > 3 || this.properties.length > 3) || (this.searches.length > 0 && this.properties.length > 0 ); 
        this.propOnly = this.searches.length === 0 && this.properties.length > 0;
      }
    },
     mounted: function () {
       let app = this;
       let cardNos = document.body.clientWidth < 1025 ? 3 : 6;
       app.getHistory('property', cardNos);
       app.getHistory('search', 10);
     }
}
</script>

<style scoped lang="scss">
@import 'app/assets/stylesheets/_vars.scss';

.hist {
  background: transparent;
  padding: .25rem;
  transition: 0.4s ease-in-out;
  margin-bottom: 1.5rem;

  &.home {
    margin: 1.25rem auto 0;
    overflow: auto;
  }

  .link {
    display: none;
  }

  &.home .sec_properties {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.15s ease-out;
  }

  &.home.only-prop {
    max-height: 29rem;
  }

  &.home.visible, .home.only-prop.visible {
    max-height: 500rem;
    overflow: auto;

    .sec_properties {
      height: auto;
      max-height: 50rem;
      overflow: unset;
      transition: max-height 0.25s ease-in;
    }

    .link {
      display: block;
      padding-top: 1rem;

      @media #{$xl-phone} {
        padding-top: 2rem;
      }
    }
  }

  &.hide {
    display: none;
  }
}

.close {
  font-size: .7rem;
  cursor: pointer;
  position: absolute; 
  bottom: 0;
  margin-left: 0.5rem;
}

.sec,
.link {
  width: 100%;
  clear: both;
}

.link {
  display: block;
  width: 100%;
  text-align: center;
  text-decoration: none;
  font-size: .938rem;
  padding-top: 1rem;

  &:hover {
    text-decoration: underline;
  }
}

.list {
  list-style: none;
}

.sec {
  margin: 1.25rem 0 0;
}

.head {
  margin: 1.5rem 0 0;
  font-size: 1.5rem;
  position: relative;
}

.sub {
  font-weight: 400;
  margin-bottom: 1.25rem;
  font-size: .875rem;
}

.toggle {
  font-size: .875rem;
  cursor: pointer;
  float: none;
  margin: 0.25rem 0 1.25rem 0;
  display: none;

  &.visible {
    display: block;
   }

   &.show {
     display: inherit;
   }

  svg {
    transform: rotate(90deg);
    background: $col_primary;
    fill: #fff;
    width: 1.25rem;
    height: 1.25rem;
    padding: .313rem;
    box-sizing: border-box;
    border-radius: 100%;
    right: -.313rem;
    top: .25rem;
    position: relative;

    .visible & {
      transform: rotate(-90deg);
    }
  }
}

.list {
  list-style: none;
  -webkit-overflow-scrolling: touch;
  margin: 0 auto;
  box-sizing: border-box;
  row-gap: 1.25rem;
  -webkit-column-gap: 1.25rem;
  -moz-column-gap: 1.25rem;
  column-gap: 1.25rem;
}

.title {
  font-weight: 400;
  margin: 1.25rem 0 2.5rem;
}

@supports (display: grid) {
  .list {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 100%;
    grid-template-columns: repeat(1, 100%);
  }
}

@media #{$xl-phone} {
  .list {
    -ms-grid-columns: 50%;
    grid-template-columns: repeat(2, calc(50% - .625rem));
    
    .isIE & li {
      float: left;
      width: calc(50% - 1.25rem);
      margin: 0 .625rem 1.25rem;
    }
  }


  .toggle {

    &.show {
      display: none;
    }
  }
}

@media #{$desktop} {

  .hist {
    margin-bottom: 2rem;
  }

  .head {
    font-size: 1.75rem;
  }

  .toggle {
    float: right;
    display: none;
    margin: -0.438em 0 1.25rem 0;
    padding-right: .625rem;

    &.show {
      display: none;
    }

    &.show.visible {
      display: block;
    }
  }

  .hist.home {
    max-width: inherit;
    margin: 1.25rem auto;
    padding: 0 1.25rem 1.25rem;

    &.properties {
      padding: 0 1.25rem;
    }
  }

  .list:not(.two) {
    -ms-grid-columns: 33%;
    grid-template-columns: repeat(3, calc(33% - .625rem));

    .isIE & li {
      width: calc(33% - 1.25rem);
    }
  }
}

</style>